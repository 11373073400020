<template>
  <div class="alert alert-warning" v-if="contract && alertWarningText">
  <strong><i class="icon fa fa-warning"></i> {{ $t("warning") }}</strong>
  {{ alertWarningText }}
  </div>
</template>

<script>
import { alertInfoConst } from "@/assets/constants.js";

export default {
  name: "PageHeaderAlert",
  computed: {
    isRoot() {
      return (this.$store.getters["user/loggedUser"] || {}).is_root || false;
    },
    contract() {
      return this.$store.getters["user/contract"];
    },
    isNotContractStateRegular() {
      let alertInfoNumber = this?.contract?.alerts_info?.number;
      return alertInfoNumber == alertInfoConst.BLOCKED;
    },
    alertWarningText() {
      if (!this.isNotContractStateRegular) return "";
      return this.$t("contract_state_alerts.not_collecting_data");
    },
  }
}
</script>