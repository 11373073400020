<template>
  <div class="wrapper" v-if="isReady">
    <template v-if="contract_id">
      <PageHeader
        v-if="showPageHeader"
        :equipmentId="equipmentId"
        :dashboard="dashboard"
        :page_header="page_header"
        :nosidebar="!showPageSideBar"
        class="no-print"
      >
      </PageHeader>
      <PageSideBar
        v-if="showPageSideBar"
        v-bind:equipmentId="equipmentId"
        v-bind:dashboard="dashboard"
        class="no-print"
      ></PageSideBar>
      <div class="content-wrapper" v-if="showPageSideBar">
        <div
          class="content dashboard-content"
          v-if="isAccountLocked && !isRouteException"
        >
          <PageHeaderAlert v-if="!busy" />
          <UserAccountLocked />
        </div>
        <div class="content dashboard-content" v-else>
          <PageHeaderAlert v-if="!busy" />
          <router-view v-on:equipmentChanged="onEquipmentChanged"></router-view>
        </div>
      </div>
      <div class="dashboard-content" v-else>
        <router-view></router-view>
      </div>
      <UserInteractionMonitor v-on:userInteraction="onUserInteraction" />
      <ControlSideBar />
      <portal-target name="modal" multiple></portal-target>
      <portal-target name="contextmenu" multiple></portal-target>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2">
          <InfoBox
            alert="info"
            icon="fa fa-user"
            v-bind:title="$t('invalid_profile')"
            v-bind:text="$t('the_specified_user_does_not_have_a_valid_profile')"
          />
        </div>
      </div>
      <div class="text-center">
        <router-link to="/"
          ><i class="fa fa-arrow-left"></i> {{ $t("back") }}</router-link
        >
      </div>
    </template>
    <portal-target name="pagezoom"></portal-target>
  </div>
  <div class="waiting" v-else>
    <div class="row">
      <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2">
        <CustomerPageBanner class="opaque"></CustomerPageBanner>
      </div>
      <div class="overlay overlay-loading">
        <i class="fa fa-refresh fa-spin"></i>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/auth";
import PageHeader from "@/components/page-header.vue";
import PageHeaderAlert from "@/components/page-header-alert.vue";
import PageSideBar from "@/components/sidebar/sidebar.vue";
import ControlSideBar from "@/components/control-sidebar/control-side-bar.vue";
import CustomerPageBanner from "@/components/customer-page-banner.vue";
import InfoBox from "@/components/info-box.vue";
import UserInteractionMonitor from "@/components/user-interaction-monitor.vue";
import UserAccountLocked from "@/components/user-plan/user-account-locked.vue";

export default {
  name: "Dashboard",
  components: {
    PageHeader,
    PageHeaderAlert,
    PageSideBar,
    CustomerPageBanner,
    InfoBox,
    UserInteractionMonitor,
    ControlSideBar,
    UserAccountLocked
  },
  data: function () {
    return {
      busy: true,
      available: true,
      isAbilityUpdated: false,
      equipmentId: 0,
      page_header: null,
      page_side_bar: null
    };
  },
  computed: {
    contract_id() {
      let user = this.user;
      return (
        (user &&
          user.user_profile &&
          user.user_profile.contract &&
          user.user_profile.contract.id) ||
        ""
      );
    },
    user() {
      return this.$store.getters["user/loggedUser"];
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    loggedUserRules() {
      return this.$store.getters["user/loggedUserRules"];
    },
    isPublicAccess() {
      return !this.user || this.user.is_public_access;
    },
    isLogged() {
      return this.user ? true : false;
    },
    isReady() {
      return (
        this.available &&
        (this.isAbilityUpdated || this.$store.getters["user/isRulesReady"])
      );
    },
    isRouteException() {
      let path = this?.$route?.path;
      if (path.includes("/dashboard/settings/")) {
        return true;
      }
      return false;
    },
    isAccountLocked() {
      return this.$store.getters["user/isAccountLocked"];
    },
    dashboard() {
      return this.$store.getters["dashboard/property"]("dashboard") || {};
    },
    showPageHeader() {
      if (this.$route.path.startsWith("/dashboard/screen/edit")) return true;
      if (this.$route.query.content_only) return false;
      // for public access, it should exists at least one option to show
      // otherwise does not display the header
      if (this.isPublicAccess && !(this?.page_header?.options || []).length) {
        return false;
      }
      return this.page_header
        ? this.page_header?.show
        : this?.dashboard?.page_header?.show;
    },
    showPageSideBar() {
      if (this.$route.path.startsWith("/dashboard/screen/edit")) return true;
      if (this.$route.query.content_only) return false;
      if (!this.$can("view", "PaginaInicialAcesso")) return false;
      return this?.page_side_bar
        ? this.page_side_bar.show
        : this?.dashboard?.page_side_bar?.show;
    },
    scriptsSync() {
      return this.$store.getters["scripts/sync"];
    }
  },
  watch: {
    isLogged: {
      handler(n, o) {
        if (o && !n) {
          this.exit();
        } else if (n && !o) {
          this.fetchScreens();
          this.updateAbility();
          this.fetchEquipment();
        }
      },
      immediate: true
    },
    $route: {
      immediate: true,
      handler(to) {
        if (
          this.$store.getters["user/loggedUser"]?.user_profile
            ?.requires_password_change &&
          to.path != "/dashboard/profile"
        ) {
          this.$router.replace("/dashboard/profile");
        }
        // reset to the default from the site (config.json)
        this.page_header = null;
        this.page_side_bar = null;
        // this configuration must be changed only by a new screen
      }
    },
    scriptsSync(n) {
      if (n) {
        // TODO: formatter is able to support multiple libraries.
        // Therefor, if more than one user library edition will be supported,
        // instead of "global" it should be taken from the changed record.
        this.$root.$formatter.library(
          "global",
          this.$store.getters["scripts/globalFunctions"]
        );
      }
    }
  },
  methods: {
    onUserInteraction(isActive) {
      this.$store.dispatch("user/interaction", isActive);
    },
    updateAbility: function () {
      var self = this;
      if (!self.isReady) {
        var rules = self.userRules;
        if (rules && rules.length) {
          self.$ability.update(rules);
        }
        self.isAbilityUpdated = true;
      }
    },
    onEquipmentChanged: function (equipmentId) {
      this.equipmentId = equipmentId ? equipmentId : 0;
      this.$store.commit("SET_EQUIPMENT_ID", equipmentId);
      this.$root.$formatter.connectorId(equipmentId ? equipmentId : "");
    },
    exit() {
      let redirectUrl = localStorage.getItem("redirect_url") || "/";
      if (redirectUrl !== "/") {
        localStorage.removeItem("redirect_url");
      }
      document.location.href = redirectUrl;
    },
    updatePageHeader(page_header) {
      this.$set(
        this,
        "page_header",
        page_header ? page_header : this?.siteLayout?.page_header
      );
    },
    updatePageLayout(config) {
      if (!config) return;
      this.$set(
        this,
        "page_header",
        config?.page_header ? config.page_header : this?.dashboard?.page_header
      );
      this.$set(
        this,
        "page_side_bar",
        config?.page_side_bar
          ? config.page_side_bar
          : this?.dashboard?.page_side_bar
      );
    },
    async fetchScreens() {
      if (!this.contract_id) return;
      this.$store
        .dispatch("dashboard/fetchScreens", {
          contract_id: this.contract_id
        })
        .then(() => {
          this.$store.dispatch("dashboard/draftCleanUp");
        });
    },
    async fetchEquipment() {
      if (!this.contract_id) return;
      this.$root.$store.dispatch("fetchEquipmentList");
    }
  },
  beforeCreate() {
    if (
      this.$root.config &&
      this.$root.config.dashboard &&
      this.$root.config.dashboard.scripts &&
      this.$root.config.dashboard.scripts.onLoad &&
      this.$root.config.dashboard.scripts.onLoad.length
    ) {
      for (var i in this.$root.config.dashboard.scripts.onLoad) {
        var src = this.$root.config.dashboard.scripts.onLoad[i];
        this.$utils
          .injectScript(src.url)
          .then((result) => {
            if (src.success) {
              window.eval(src.success);
            }
          })
          .catch((result) => {
            if (src.fail) {
              window.eval(src.fail);
            }
          });
      }
    }
    this.available = true;
  },
  created: function () {
    var user = this.user;
    if (user) {
      this.updateAbility();
    } else {
      let auth = new AuthService();
      if (auth.access_token()) {
        this.$store
          .dispatch("user/login", {keep_alive: auth.keepAlive()})
          .catch(() => {
            this.$router.push("/");
          });
      } else {
        const href = document.location.href;
        let url = "/";
        if (/\/dashboard/.test(href) && !/redirect/.test(href)) {
          url = `/?redirect_url=${encodeURIComponent(
            href.replace(document.location.origin, "")
          )}`;
        }
        this.$router.push(url);
        return;
      }
    }
    // this.updatePageHeader();
    // this.$root.$on("dashboard:page_header", this.updatePageHeader);
    this.updatePageLayout();
    this.$root.$on("dashboard:page_layout", this.updatePageLayout);
  },
  beforeDestroy() {
    this.$root.$off("dashboard:page_layout", this.updatePageLayout);
  },
  mounted() {
    $(document).trigger("resize");
    const self = this;
    this.$root.$emit("controlSidebar:setContent", null);
    this.$utils.orientationChanged(() => {
      self.available = false;
      self.$nextTick(() => {
        self.available = true;
      });
    });
  }
};
</script>

<style scoped>
@media screen {
  .content-wrapper {
    min-height: 600px;
    padding: 5px 5px 0 5px;
  }

  .content-wrapper:fullscreen {
    padding: 0;
  }

  .dashboard-content:fullscreen {
    padding: 0;
  }

  .wrapper {
    position: relative;
    /* overflow-x: hidden;
    overflow-y: initial; */
    overflow: hidden;
    min-height: 100vh;
  }

  .content {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .waiting {
    background-color: #ffffff;
  }
  .overlay-loading {
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 80px;
    text-align: center;
    margin-top: 2%;
    color: #607d8b;
    width: 100%;
    padding: 80px;
  }
  .opaque {
    opacity: 0.5;
  }
}

@media print {
  .no-print {
    display: none;
  }

  .wrapper {
    position: relative;
    overflow: none;
    background-color: #fff !important;
  }

  .content-wrapper {
    min-height: auto;
    padding: 0 !important;
    margin-left: 0 !important;
    background: white !important;
  }

  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 0 !important;
  }

  .content {
    padding: 0 !important;
    margin: 0 !important;
  }
  .waiting {
    display: none;
  }
  .overlay-loading {
    display: none;
  }
  .opaque {
    display: none;
  }
}
</style>

<style>
@media print {
  .nav-tabs-custom > ul > li.active {
    display: none;
  }
  .box.box-primary,
  .box.box-warning,
  .box.box-info {
    border-top-color: transparent;
  }
}
</style>
