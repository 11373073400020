<template>
  <div class="container">
    <div class="box box-solid box-account-locked">
      <div class="box-body" v-if="busy || !contract">
        <div class="row">
          <div class="col-md-12 col-padding text-center text-gray">
            <Icon name="fa fa-refresh fa-4x fa-spin" />
          </div>
        </div>
      </div>
      <div class="box-body" v-else>
        <div class="row">
          <div class="col-md-5 col-padding">
            <img src="/static/common/images/telemetria.png" class="img-fluid" />
          </div>
          <div class="col-md-7 col-padding">
            <h2 class="headline" v-if="title">
              {{ title }}
            </h2>
            <h3 style="margin-bottom: 20px;" v-if="subtitle">
              {{ subtitle }}
            </h3>
            <p v-if="text_1">
              {{ text_1 }}
            </p>
            <p v-if="text_2">
              <strong v-html="text_2">
              </strong>
            </p>
            <p class="text-center" style="margin-top: 40px;" v-if="isRoot">
              <template v-if="usesPaymentGateway">
                <button 
                  class="btn btn-primary btn-lg contract" 
                  :disabled="busy"
                  @click="onClick"
                >
                  <Icon :name="buttonIcon" />&nbsp;
                  {{ buttonText }}
                </button>
              </template>
              <template v-else>
                <h4>
                  {{ $t("contact_commercial_department") }}
                </h4>
                <p>
                  <Icon name="fa fa-envelope" />&nbsp;
                  <strong><a :href="'mailto:'+email">{{ email }}</a></strong>
                </p>
                <p>
                  <Icon name="fa fa-phone" />&nbsp;
                  <strong>{{ phone }}</strong>
                </p>
              </template>
            </p>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :open="showUpgradePlan"
      @update:open="showUpgradePlan = $event ? true : false"
      @loading="loading"
      :title="$t('upgrade_plan')"
      :footer="false"
      iconClass="fa fa-arrow-circle-up"
      :fitContent="false"
      :backdrop="busy ? 'static' : 'true'"
      :component_busy="busy"
    >
      <template #content>
        <UserPlanUpgradeB />
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/widgets/modal.vue";
import Icon from '@/components/icons/icon.vue';
import { contractStateConst } from '@/assets/constants.js';
import UserPlanUpgradeB from "@/components/user-plan/user-plan-upgrade-b.vue";

export default {
  name: "UserAccountLocked",
  components: {
    Icon,
    Modal,
    UserPlanUpgradeB
},
  props: {
  },
  data() {
    return {
      busy: true,
      showUpgradePlan: false,
    };
  },
  computed: {
    isRoot() {
      return (this.$store.getters["user/loggedUser"] || {}).is_root || false;
    },
    usesPaymentGateway() {
      return this.$root?.config?.references?.payment_gateway;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    contractStateId() {
      return this?.contract?.contract_state?.id;
    },
    title() {
      if (!this.isRoot) {
        return this.$t("account_locked.warning");
      } if (this.contractStateId == contractStateConst.TEST) {
        return this.$t("account_locked.test_over_title");
      } else {
        return this.$t("account_locked.payment_not_confirmed_title");
      }
    },
    subtitle() {
      if (!this.isRoot) {
        return this.$t("account_locked.contract_requires_attention");
      } if (this.contractStateId == contractStateConst.TEST) {
        return this.$t("account_locked.test_over_subtitle");
      } else {
        return this.$t("account_locked.payment_not_confirmed_subtitle") + ' ' + this.formattedExpiresAt;
      }
    },
    text_1() {
      if (!this.isRoot) {
        return this.$t("account_locked.please_contact_admin");
      } else if (this.contractStateId == contractStateConst.TEST) {
        return this.$t("account_locked.test_over_text_1");
      } else {
        return this.$t("account_locked.payment_not_confirmed_text_1");
      }
    },
    text_2() {
      if (!this.isRoot) {
        return "";
      } if (this.contractStateId == contractStateConst.TEST) {
        return this.$t("account_locked.test_over_text_2");
      } else {
        return this.$t("account_locked.payment_not_confirmed_text_2");
      }
    },
    buttonText() {
      if (this.contractStateId == contractStateConst.TEST) {
        return this.$t("do_contract");
      } else {
        return this.$t("my_plan");
      }
    },
    buttonIcon() {
      if (this.contractStateId == contractStateConst.TEST) {
        return "fa fa-check";
      } else {
        return "fa fa-credit-card";
      }
    },
    formattedExpiresAt() {
      let expiresAt = this?.contract?.expires_at;
      if (expiresAt == null) return '';
      let minusOneSecond = new Date(expiresAt);
      minusOneSecond.setSeconds(minusOneSecond.getSeconds() - 60);
      return moment(minusOneSecond).format("L");
    },
    email() {
      return "vendas@hitecnologia.com.br";
    },
    phone() {
      return "+55 (19) 2139-1700";
    }
  },
  watch: {
  },
  methods: {
    onClick() {
      if (this.contractStateId == contractStateConst.TEST) {
        this.showUpgradePlan = true;
      } else {
        this.$router.push("/dashboard/settings/billing");
      }
    },
    loading(state) {
      this.busy = state;
    },
  },
  mounted() {
    this.busy = false;
  }
};
</script>

<style scoped>
  .img-fluid {
    width: 100%;
    height: auto;
  }
  .headline {
    font-size: 24pt;
    font-weight: bold;
  }
  .box-account-locked {
    margin-top: 40px;
  }
  .btn.contract {
    font-size: 14pt;
  }
  .col-padding {
    padding: 40px;
  }
</style>
